.navbar {
    /* position: relative; */
    width: 100%;
    /* overflow: hidden; */
    background-color: rgba(26, 25, 25, 0.295);
    /* color: blue; */
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    /* background-color:transparent; */
    /* color: red; */
    /* position:relative; */
    top: 0;
    width: 100%;
    height: 200px;
    /* border-bottom: 2px solid black; */
}

li {
    float: left;
}

li a {
    display: block;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 80px;
    font-family: "Playwrite NG Modern";
    color: black;
    animation: moveAndFlip 10s infinite;
}

@keyframes moveAndFlip {
    0% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(calc(100vw - 100px)) rotateY(360deg);
    }
    100% {
        transform: translateX(0);
    }
}
